<template>
  <v-dialog
    v-if="dataDialog.open"
    v-model="dataDialog.open"
    :persistent="dataDialog.type === 'error'"
    max-width="700"
    transition="slide-y-transition"
  >
    <v-card class="white" data-cy="infoDialog" data-testid="InfoDialog">
      <div class="rounded-0 pa-4" style="height: 20vh" :class="dialogType">
        <div class="d-flex justify-end">
          <v-icon @click="dataDialog.open = false" color="white" size="32"> mdi-close </v-icon>
        </div>
      </div>
      <div class="pa-6 text-left grey--text">
        <div data-cy="titleDialog" class="text-h5 font-weight-bold">{{ dataDialog.title }}</div>
        <div class="subtitle-2" v-if="dataDialog.subtitle">
          {{ dataDialog.subtitle }}
        </div>
        <div class="mt-4">
          <div
            v-if="typeof dataDialog.message === 'string'"
            class="font-weight-regular text-h6"
            data-cy="messageDialog"
            v-html="dataDialog.message"
          />
          <Steps v-else :steps="dataDialog.message" />
        </div>

        <v-checkbox
          v-if="dataDialog.toggleOption"
          v-model="toggleChecked"
          :label="dataDialog.toggleOption.message"
        />

        <div class="d-flex flex-column flex-lg-row justify-lg-end mt-8">
          <v-btn
            v-if="shouldShowHelpButton"
            :href="sendMessage"
            target="_blank"
            large
            class="mr-lg-4 mb-4 mb-lg-0"
            color="secondary"
            dark
            outlined
            rounded
            elevation="0"
          >
            Pedir ajuda
          </v-btn>
          <v-btn
            v-if="shouldShowCloseButton"
            class="px-4 mr-lg-4 mb-lg-0 mb-4"
            :color="
              !dataDialog?.close?.style.backgroundColor
                ? 'secondary'
                : dataDialog?.close?.style.backgroundColor
            "
            :style="dataDialog?.close?.style || {}"
            large
            rounded
            :outlined="shouldCloseButtonBeOutlined"
            elevation="0"
            data-cy="infoDialogButtonClose"
            @click="this.handleClose"
          >
            Fechar
          </v-btn>
          <v-btn
            v-if="this.dataDialog.action"
            class="px-4"
            :color="!dataDialog?.action?.style ? 'primary' : ''"
            :style="dataDialog?.action?.style || {}"
            large
            rounded
            elevation="0"
            data-cy="infoDialogButtonAction"
            @click="this.executeAction"
          >
            {{ this.dataDialog.action.name }}
          </v-btn>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import InfoDialogController from "./InfoDialogController";

export default InfoDialogController;
</script>

<style scoped lang="scss">
.error-section {
  background-image: url("../../../assets/info_dialog/errorImage.webp");
  background-position: center;
  background-blend-mode: multiply;
  background-size: 70%;
}

.success-section {
  background-image: url("../../../assets/info_dialog/successImage.webp");
  background-position: center;
  background-blend-mode: multiply;
  background-size: 70%;
}

.info-section {
  background-image: url("../../../assets/info_dialog/infoImage.webp");
  background-position: center;
  background-blend-mode: multiply;
  background-size: 70%;
}
</style>
